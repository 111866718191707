<!-- Header десктоп -->

<script setup>
import { computed } from 'vue'
import { RouterLink } from 'vue-router'
import { useGameStore } from '@/stores/gameStore'

const gameStore = useGameStore()

const circleProgressbarStyle = computed(() => {
  return { '--progress': `${gameStore.CalcTipTimerAngle()}` }
})
</script>

<template>
  <div v-if="gameStore.stage.hideInterface == 0" class="header-desktop">
    <div class="header-desktop-container">
      <div class="header-desktop-elements">
        <div class="header-desktop-logo">
          <div class="logo-12"></div>
        </div>
        <nav>
          <ul class="header-desktop-menu">
            <li :class="{ 'header-desktop-active': gameStore.routePath == 'stage' }">
              <RouterLink to="/">
                <div>{{ $t('tabs.gameDesktop') }}</div>
              </RouterLink>
            </li>
            <li
              :class="{
                'header-desktop-active': gameStore.routePath == 'tips',
                'animate__animated animate__tada': Boolean(gameStore.notify.tabTips)
              }"
            >
              <RouterLink to="/tips">
                <div>{{ $t('tabs.tipsDesktop') }}</div>
                <div v-if="Boolean(gameStore.notify.tabTips)">
                  <span class="badge-notification">
                    {{ gameStore.notify.tabTips }}
                  </span>
                </div>
                <div class="badge-notification-container" v-else-if="gameStore.stage?.toNextTip">
                  <div class="circle-progressbar" :style="circleProgressbarStyle"></div>
                </div>
              </RouterLink>
            </li>
            <li :class="{ 'header-desktop-active': gameStore.routePath == 'chats' }">
              <RouterLink to="/chats">
                <div>{{ $t('tabs.messagesDesktop') }}</div>
              </RouterLink>
            </li>
            <li
              v-if="gameStore.allowChooseStage == '1' && gameStore.allowInterruptStage == '1'"
              :class="{ 'header-desktop-active': gameStore.routePath == 'chooseStage' }"
            >
              <RouterLink to="/chooseStage">
                <div>{{ $t('tabs.stagesDesktop') }}</div>
              </RouterLink>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<style scoped>
.header-desktop {
  display: none;
}
.badge-notification-container {
  position: absolute;
  transform: translate(200%, -50%);
  z-index: 1;
  color: #ffffff;
  border-radius: 100rem;
  font-size: 9px;
  line-height: 1;
  padding: 3px 5px 3px 5px;
}
.circle-progressbar {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: conic-gradient(#0078f7 var(--progress), #0078f72e 0deg);
  font-size: 0;
}

@media (min-width: 1024px) {
  .header-desktop {
    display: flex;
    min-height: 48px;
    align-items: stretch;
    border-bottom: 1px solid #50546c;
    position: sticky;
    top: 0;
    z-index: 1000;
    background-image: linear-gradient(90deg, #05081b 5%, #09124e 100%);
    background-size: auto;
    background-repeat: no-repeat;
    background-position: center 120%;
  }
  .header-desktop-container {
    padding-left: 60px;
    padding-right: 60px;
    width: 100%;
  }
  .header-desktop-elements {
    display: flex;
    flex-direction: row;
    color: #ffffff;
    justify-content: space-between;
    align-items: stretch;
    height: 100%;
  }
  .header-desktop-logo,
  .header-desktop-onstage-data {
    display: flex;
    align-items: center;
  }
  .header-desktop-logo > img {
    height: 20px;
    align-self: auto;
  }
  .header-desktop-onstage-data .onstage-data {
    display: flex;
    color: #ffffff;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
  }
  .header-desktop-onstage-data .onstage-time,
  .onstage-answers {
    display: flex;
  }
  .header-desktop-menu {
    display: flex;
    gap: 43px;
  }
  .header-desktop-menu ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: stretch;
    list-style-type: none;
  }
  .header-desktop-menu li a {
    display: flex;
    gap: 0;
    color: #9c9fb1;
  }
  .header-desktop-menu li {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0em;
    cursor: pointer;
  }
  .header-desktop-menu li.header-desktop-active a {
    color: #ffffff;
    transform: scale(1.1);
  }
  .header-desktop-menu li:first-child {
    margin-left: 0;
  }
  .header-desktop-menu .badge-notification {
    top: 0px;
    right: -5px;
  }
}
</style>
