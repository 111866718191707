export default {
  general: {
    loading: 'Загрузка...',
    copy: 'Копировать',
    copied: 'Скопировано!',
    next: 'Продолжить'
  },
  tabs: {
    tipsMobile: 'подсказки',
    tipsDesktop: 'Подсказки',
    gameMobile: 'игра',
    gameDesktop: 'Игра',
    messagesMobile: 'сообщения',
    messagesDesktop: 'Сообщения',
    stagesMobile: 'этапы',
    stagesDesktop: 'Этапы'
  },
  errors: {
    error: 'Ошибка',
    noAuth: 'Используйте ссылку для входа',
    lostConnection: 'Интернет-соединение потеряно',
    noRoute: 'Не установлен маршрут! Обратитесь к организатору.'
  },
  timerToGame: {
    gameWillStartAt: 'Игра начнётся в'
  },
  sharedLink: {
    errors: {
      title: 'Ой',
      teamLimit: 'К сожалению, превышен лимит участников. Обратитесь к организатору.',
      wrongData: 'Ссылка неправильная или сейчас не действует. Обратитесь к организатору.'
    },
    wantContinue: 'Хотите продолжить?',
    wantContinueDesc:
      'Вы уже авторизовывались ранее на этом устройстве. Вернуться к прежнему событию или начать новое?',
    continueOldSession: 'Продолжить старое',
    startNewSession: 'Начать новое',
    sendLinkToTeam: 'Отправьте ссылку участникам вашей команды',
    sendLinkToTeamDesc: 'Тогда вы сможете войти с нескольких устройств одновременно.'
  },
  testConsole: {
    title: 'Тестовый режим',
    description: 'Эта панель доступна только в режиме тестирования. Игрокам она недоступна.',
    reset: 'Начать сначала',
    agreeReset: 'Начать игру всё равно'
  },
  time: {
    moreThen: 'более',
    sec: 'сек',
    min: 'мин',
    hr: 'ч',
    dz: 'дн'
  },
  progressBar: {
    untilStageStop: 'До завершения этапа осталось '
  },
  stageTelemetry: {
    timeOnStage: 'Время на этапе:',
    correctAnswers: 'Правильные ответы:',
    answers: 'Ответы:',
    details: 'Подробнее',
    remainingAnswers: 'Остались ответы:',
    remaining: 'Остались:'
  },
  answersLogs: {
    title: 'История введённых ответов',
    empty: 'Пока пусто',
    btnBack: 'Вернуться к заданию',
    correct: 'правильный',
    wrong: 'неправильный',
    again: 'повтор',
    remainingTitle: 'Остались ответы:'
  },
  chats: {
    title: 'Чаты',
    disabled: 'Чаты временно недоступны'
  },
  chooseStage: {
    title: 'Выберите этап'
  },
  stage: {
    answerInputPlaceholder: 'Введите ответ',
    send: 'Отправить',
    correct: 'правильный',
    wrong: 'неправильный',
    again: 'повтор'
  },
  tips: {
    title: 'Подсказки',
    empty: 'Подсказок пока нет. Вы получите уведомление, если они появятся.',
    untilNextTip: 'До следующей подсказки:'
  },
  dialogs: {
    sureChooseStage: {
      title: 'Вы действительно хотите перейти далее?',
      body: 'Вернуться обратно будет невозможно',
      agree: 'Перейти',
      cancel: 'Отменить'
    },
    sureNextStage: {
      title: 'Вы действительно хотите перейти далее?',
      body: 'Вернуться обратно будет невозможно',
      agree: 'Перейти',
      cancel: 'Отменить'
    },
    stageTransition: {
      correctAnswer: 'правильный ответ',
      goNext: 'Переходим далее'
    }
  },
  gameOver: {
    promo:
      'На платформе 12codes вы можете создать свою игру, квест или экскурсию! Более 40 городов России и СНГ уже уже пользуются',
    landingLink: 'https://12codes.com'
  }
}
