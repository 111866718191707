// Функции работы со временем
import dayjs from 'dayjs'
import { i18n } from '@/main.js'

let gameStore = null

const Init = async (appGameStore) => {
  gameStore = appGameStore
}

// асинхронная задержка
const Delay = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const MSToTime = (microSeconds) => {
  if (microSeconds < 0) microSeconds = 0

  const seconds = Math.floor(microSeconds / 1000)
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')
  const formattedSeconds = remainingSeconds.toString().padStart(2, '0')

  // Возвращаем время в формате "HH:mm:ss"
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
}

export function MSToHumanTime(ms, htmlFormat = 0) {
  const seconds = Math.floor(ms / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const separator = htmlFormat ? '&nbsp' : ' '

  if (days)
    return i18n.global.t('time.moreThen') + separator + days + separator + i18n.global.t('time.dz')
  if (hours)
    return i18n.global.t('time.moreThen') + separator + hours + separator + i18n.global.t('time.hr')
  if (minutes)
    return (
      (minutes % 60) +
      separator +
      i18n.global.t('time.min') +
      separator +
      (seconds % 60) +
      separator +
      i18n.global.t('time.sec')
    )

  return (seconds % 60) + separator + i18n.global.t('time.sec')
}

// 2023-09-26 05:07:39 превращает в объект даты
const DTStrToDate = (dateStr) => {
  const [datePart, timePart] = dateStr.split(' ')
  const [year, month, day] = datePart.split('-').map(Number)
  const [hour, minute, second] = timePart.split(':').map(Number)
  const jsMonth = month - 1 // Месяцы в JavaScript начинаются с 0, поэтому вычитаем 1 из месяца.

  return new Date(year, jsMonth, day, hour, minute, second)
}

const TZOffset = () => {
  let now = new Date()
  return now.getTimezoneOffset()
}

// Разница между UTC-временами
const UTCToLocal = (dtUTC, options = {}) => {
  let tzOffset = TZOffset()

  let dtJS = DTStrToDate(dtUTC)
  let dtNew = new Date(+dtJS - tzOffset * 60 * 1000)

  if (options?.short) return dayjs(dtNew).format('HH:mm')
  return dayjs(dtNew).format('HH:mm DD.MM.YYYY')
}

const DrawStageTime = (msOnStage, msStageAP) => {
  if (msStageAP) gameStore.progressAP = ((msOnStage / msStageAP) * 100).toFixed(2)
  else gameStore.progressAP = 0
  gameStore.secondsUntilAP = msStageAP - msOnStage
  gameStore.stageTelemetry.stopwatch = MSToTime(msOnStage)

  gameStore.stageTelemetry.ap = msStageAP ? MSToTime(msStageAP) : null
}

const CalcTimeOnStage = () => {
  if (gameStore.myUTCOffset === null) return 0
  if (!gameStore.stage.startDT) return 0
  return CalcUTCOffset(gameStore.stage.startDT) - gameStore.myUTCOffset
}

// Переводим оставшиеся секунды в угол (для кругового прогресбара)
const ElapsedSecondsToAngle = (elapsedSeconds, totalSeconds) => {
  return `${Math.round((elapsedSeconds / totalSeconds) * 360)}deg`
}

const Tick = () => {
  // Время на этапе
  let msOnStage = CalcTimeOnStage() + Number(gameStore?.stage?.plusSecOnStage ?? 0) * 1000
  if (gameStore.stage) gameStore.stage.msOnStage = msOnStage
  let msAP = Number(gameStore?.stage?.ap) * 1000

  // Если должен сработать автопереход
  if (msOnStage && msAP && msOnStage > msAP) {
    gameStore.GetState(false)
    return false
  }
  // Если пора показать подсказку
  let secToNextTip = gameStore.stage?.toNextTip?.sec ?? 0
  if (msOnStage && secToNextTip && msOnStage / 1000 > secToNextTip) {
    gameStore.GetState(false)
    return false
  }

  DrawStageTime(msOnStage, gameStore?.stage?.ap * 1000)
}

// Разница между UTC-временами
const CalcUTCOffset = (UTC1, UTC2 = null) => {
  let date1 = DTStrToDate(UTC1)
  let UTCDT1 = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate(),
    date1.getHours(),
    date1.getMinutes(),
    date1.getSeconds()
  )

  let date2 = new Date()
  let UTCDT2 = Date.UTC(
    date2.getUTCFullYear(),
    date2.getUTCMonth(),
    date2.getUTCDate(),
    date2.getUTCHours(),
    date2.getUTCMinutes(),
    date2.getUTCSeconds()
  )
  if (UTC2) {
    date2 = DTStrToDate(UTC2)
    UTCDT2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate(),
      date2.getHours(),
      date2.getMinutes(),
      date2.getSeconds()
    )
  }

  return UTCDT2 - UTCDT1
}

export const timeFunctions = {
  Init,
  CalcUTCOffset,
  ElapsedSecondsToAngle,
  MSToHumanTime,
  Delay,
  UTCToLocal,
  Tick
}
