<!-- Вкладка подсказок -->

<script setup>
import { computed } from 'vue'
import { i18n } from '@/main.js'
import StageTelemetryData from '@/components/StageTelemetryData.vue'
import Prorgessbar from '@/components/Progressbar.vue'
import { timeFunctions } from '@/services/time'
import { useGameStore } from '@/stores/gameStore'
const gameStore = useGameStore()

let prevTimeUntilTipText = ''

const timeUntilTipText = computed(() => {
  let elapsedSeconds = gameStore.stage?.msOnStage / 1000 ?? 0
  if (Number.isNaN(elapsedSeconds)) return prevTimeUntilTipText

  let totalSeconds = gameStore.stage?.toNextTip ? gameStore.stage.toNextTip.sec : 0

  let secondsLeft = totalSeconds - elapsedSeconds
  if (secondsLeft > 0)
    prevTimeUntilTipText =
      i18n.global.t('tips.untilNextTip') + ' ' + timeFunctions.MSToHumanTime(secondsLeft * 1000, 1)
  if (secondsLeft == 0) prevTimeUntilTipText = i18n.global.t('general.loading')
  return prevTimeUntilTipText
})
</script>

<template>
  <div class="tab-wrapper tab-wrapper-telemetry">
    <StageTelemetryData />
  </div>
  <div class="tab-wrapper flex-1" id="tab-tips">
    <!-- Заголовок -->
    <div class="tab-tips-header">
      <h1 class="tab-h1">{{ $t('tips.title') }}</h1>
    </div>

    <!-- Подсказок нет -->
    <div class="tab-tips-body content-text" v-if="!gameStore.stage.tips.shown.length">
      {{ $t('tips.empty') }}
    </div>

    <!-- До следующей подсказки -->
    <div class="tab-tips-body content-text" v-if="gameStore.stage?.toNextTip">
      <div class="tab-tips-tip-container tab-tips-until-next" v-html="timeUntilTipText"></div>
    </div>

    <!-- Подсказки -->
    <div class="tab-tips-body" v-if="gameStore.stage.tips.shown.length">
      <div v-for="tip in gameStore.stage.tips.shown">
        <div class="tab-tips-tip-container">
          <h2 class="tab-tip-title">{{ tip?.title }}</h2>
          <div v-html="tip?.content?.blocks" class="content-container"></div>
        </div>
      </div>
    </div>
    <Prorgessbar :inCard="1" />
  </div>
</template>

<style scoped>
.tab-tips-body {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.tab-tips-tip-container {
  border-left: 2px solid #0078f7;
  padding-left: 10px;
  margin-top: 45px;
}

.tab-tips-header {
  margin: 0;
  margin-bottom: 17px;
}

.tab-tip-title {
  font-size: 18px;
  font-weight: bold;
}
.tab-tips-until-next {
  background: #0078f71a;
  color: #b3c3d2;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px !important;
}

/* > 1024 */
@media (min-width: 1024px) {
  .tab-tips-body > div {
  }

  .tab-tips-tip-container {
    margin: 0;
  }
  .tab-tips-until-next {
    margin-top: 45px !important;
  }
}
</style>
